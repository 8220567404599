@import "../../../../styles/variables";
@import '../../../../../node_modules/normalize-scss/sass/normalize';
@include normalize();

.hr--primary {
  background: $brand-primary;
  color: $brand-primary;
  border: none;
  display: block;
  height: 2px;
  background: $brand-primary;
  margin: 1rem 0 $grid-gutter * 2;

  @media screen and (min-width: $screen-xs){
    width: 95%;
  }

  @media screen and (min-width: $screen-sm) {
    width: 95%;
  }

  @media screen and (min-width: $screen-md) {
    width: 50%;
  }

  @media screen and (min-width: $screen-lg) {
    width: 50%;
  }
}

@import 'variables';

@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin container() {
  padding: $grid-gutter * .75 $gutter-mobile;
  @media screen and (min-width: $screen-sm) {
    padding: $grid-gutter * 1.5 $gutter-desktop;
  }
  @media screen and (min-width: $screen-lg) {
    max-width: $screen-lg;
    margin-left: auto;
    margin-right: auto;
    &:before,
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

@mixin full-bleed() {
  position: relative;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
}

// @extend .grid to outer
// @extend .grid-col to make this column
@mixin flex-grid ($size) {
	max-width: percentage($size);
	flex-basis: percentage($size);
}

@mixin make-grid($viewport, $size) {
	@if ($viewport == '') {
	  @include flex-grid ($size);
	}

	@if ($viewport != '') {
		@media #{$viewport} {
		  @include flex-grid ($size);
		}
	}
}

@mixin spinner() {
    0% {
      opacity: .5;
      transform: rotate(0);
    }

    25% { opacity: 1; }
    50% { opacity: .5; }
    75% { opacity: 1; }

    100% {
      opacity: .5;
      transform: rotate(360deg);
    }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "node_modules/rc-datepicker/lib/style";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

$_darkGrey: #354052;
$input-border-color: #ced0da;
$input-border-color-hover: $input-border-color;
$input-button-icon-color-hover:#9098a7;
$input-border-color-open: #2da1f8;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

.icon-rc-datepicker_clear::before {
  content: prepend-slash($fa-var-close);
}

.icon-rc-datepicker_prev::before {
  content: prepend-slash($fa-var-chevron-left);
}

.icon-rc-datepicker_next::before {
  content: prepend-slash($fa-var-chevron-right);
}

.icon-rc-datepicker_calendar::before {
  content: prepend-slash($fa-var-calendar);
}

.datepicker {
  .input-field:after{
    bottom: 2.6rem;
  }

  .react-datepicker-component + label {
    &,
    &:not(:placeholder-shown) {
      opacity: 1;
      top:-1.35rem;
    }

    &,
    &:placeholder-shown {
        opacity: 0;
    }

    // need to separate this from above css selector
    // otherwise, IE11 does not render correctly
    &.placeholder-shown {
        opacity: 0;
    }
  }


  $input-background: transparent;
  $input-background-hover: transparent;
  $input-background-open: transparent;

  $input-color: $_darkGrey;
  $input-color-hover: $input-color ;
  $input-color-open: $input-color;
  $input-color-has-value: $_darkGrey;

  $input-placeholder-color: $gray-light;
  $input-placeholder-color-hover: $gray-light;
  $picker-header-background: $brand-primary;

  .react-datepicker-component {


    .react-datepicker-input {
      background: $input-background;
      border: 0px solid $input-border-color;

      input {
        display: block;
        width: 100%;
        background: #fff;
        padding: $grid-gutter 0;
       // margin-top: 1rem;
        // margin-top: $grid-gutter * 2;
        // margin-bottom: $grid-gutter;
        border: 1px solid $gray-light;
        border-width: 0 0 1px;
        box-sizing: border-box;
        box-shadow: none;
        appearance: none;
        font-size: $font-size-large;
        font-weight: $font-weight;
        transition: border-color 0.3s ease;
        margin-top: 25px;

        &::placeholder {
          color: $gray-light;
          font-weight: normal;
        }

        // Customize the `:focus` state to imitate native WebKit styles.
        &:focus {
          border-color: $brand-primary;
          border-bottom-width: 2px;
          outline: 0;
        }

        &:disabled {
          opacity: 0.5;
        }

        &,
        &:placeholder-shown {
          + label {
            opacity: 0;
          }
        }

        // need to separate this from above css selector
        // otherwise, IE11 does not render correctly
        &.placeholder-shown {
          + label {
            opacity: 0;
          }
        }

        &:not(:placeholder-shown),
        &:not(.placeholder-shown):not(.rrui__input) {
          &:not(:focus) {
            border-color: #000;
            border-width: 0 0 1px;
          }

          + label {
            opacity: 1;
          }
        }

        .container--dark & {
          margin-top: 0;
          padding: $grid-gutter * 1.5;
          padding-bottom: ($grid-gutter - 0.25) * 1.5;
          border-bottom-width: 0.25rem !important;
          border-color: #fff;
          border-radius: $border-radius;

          &:not(:placeholder-shown),
          &:not(.placeholder-shown) {
            margin-top: $grid-gutter * 2;
            &:not(:focus) {
              border-color: #fff;
            }
          }

          + label {
            color: $brand-primary;
          }
        }
      }

      &:hover {
        background: $input-background-hover;
        border: 0px solid $input-border-color-hover;

        input {
          color: $input-color-hover;

          @include placeholder() {
            color: $input-placeholder-color-hover;
          }
        }

        .button-wrapper .input-button {
          color: $input-button-icon-color-hover;
        }
      }

      &.is-open {
        background: $input-background-open;
        border: 0px solid $input-border-color-open;
      }
    }
  }

   .react-datepicker {
    &.floating {
      left: 213px;

      @media (max-width: 768px) {
        left: 0px;
      }
    }

    .react-datepicker-container {
      .react-datepicker-top {
        background: $picker-header-background;
      }
    }
  }
}


@import "src/styles/variables";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.data-table {
  width: 100%;
  margin: 1rem 0;
  padding: 10px;
  thead {
    text-align: left;
    border-bottom: thin solid black;
  }
  tbody {
    tr:nth-child(even) {
      background-color: $bg-info-secondary;
    }
    td {
      // text-align: center;
      padding: 0.5rem;
    }
  }

  .collapsColumn {
    @media screen and (max-width: $screen-xs) {
      display: none;
    }
  }

  .data-table__row {
    &--single {
      background-color: $bg-table-single;
    }
    &--multiple {
      tr:nth-child(n) {
        border-bottom: dashed 2px $gray-lighter;
      }

      tr:nth-child(even) {
        background-color: $bg-primary;
      }
    }
  }

  .data-table__data__header {
    display: none;
  }

  &--mobile-card {
    @media screen and (max-width: $screen-xs) {
      thead {
        display: none;
      }
      tbody {
        tr {
          display: flex;
          flex-wrap: wrap;

          td {
            text-align: left;
            flex: 1 0 26%;

            &.order-1 {
              order: 1;
            }
            &.order-2 {
              order: 2;
            }
            &.order-3 {
              order: 3;
            }
            &.order-4 {
              order: 4;
            }
            &.order-5 {
              order: 5;
            }
            &.order-6 {
              order: 6;
            }
          }
        }
        .data-table__data__header {
          color: $gray-light;
          font-size: 0.8rem;
          display: block;
        }
      }
    }
  }
}

.details-block {
  &::after {
    font-family: FontAwesome;
    position: relative;
    left: 0.5rem;
    content: prepend-slash($fa-var-chevron-right);
  }
}

.row_gray {
  color: $gray-blue;
}

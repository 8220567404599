.region-marker {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    z-index: 99999999999999;
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

.moneris {
  margin: $grid-gutter 0;
  iframe {
    width: 100%;
    margin-bottom: $grid-gutter;
  }

  &--static {
    width: 100%;
    margin-bottom: $grid-gutter * 2;

    &__line2 {
      display: flex;

      &__expiry-date {
        max-width: 50%;
        padding-right: $grid-gutter * 2;
      }

      &__cvv {
        max-width: 30%;
      }
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

$tooltipicon: $icon-size + 2;
.radio {
  margin-top: $grid-gutter;

  &__field {
    display: inline-block;
  }

  &__field-compact {
    display: inline-block;
    max-width: 70px;
  }
  .padding_left {
    padding-left: 50px;
  }

  input[type="radio"] {
    // WATCH OUT
    position: absolute;
    left: -100vw;

    // Focus for radio, and checkbox - should have for file field too
    &:focus {
      @include tab-focus;
    }

    + label {
      position: relative;
      padding: $grid-gutter / 4 1.5rem;
      // padding-left: 1.5rem;
      display: inline-block;
      font-size: $font-size-small;
      font-weight: $font-weight;
      min-width: 7rem; // WATCH OUT: used only for lang field so far, might not need this
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        display: inline-block;
        transition: all .2s ease;
        transform: translateY(-50%);
        width: 1rem;
        text-align: center;
      }


      &:before {
        background: #fff;
        height: 1rem;
        border-radius: 50%;
        border: 1px solid $gray-light;
      }

      &:after {
        @include fa-icon();
        left: 1px;
        color: $brand-primary;
        font-size: 75%;
      }
    }

    &:checked {
      + label {
        &:after {
          content: prepend-slash($fa-var-circle);
        }
      }
    }

    &:disabled {
      + label {
        opacity: .6;
      }
    }
  }
}

.datepicker.billingDate.input {
  input {
    margin-top: 0px;
  }

  .react-datepicker {
    &.floating {
      left: 117px;
      @media (max-width: 768px) {
        left: 0px;
      }
    }

    &-input {
      @media (max-width: 768px) {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
}



@import "src/styles/variables";
@import "src/styles/mixins";

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9; // nav cover is 9

  // DESIGN CHANGE
  // .container--dark & {
  //   border-bottom: 2px solid $brand-primary;
  // }

  &__main {
    padding: $grid-gutter * .75 $gutter-mobile;
    position: relative;
    background: #000;
    color: #fff;
    @media screen and (min-width: $screen-sm) {
      padding: $grid-gutter * 1.5 $gutter-desktop;
    }
  }

  &__sub {
    background: #fff;
    box-shadow: 0 0 1rem rgba(0,0,0,.3);
    &-inner {
      @include container();
    }
  }

  &__tools {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    text-align: right;

    // @media screen and (min-width: $screen-sm) {
    //   right: 0;
    // }

    &-list {
      display: none;
      margin: $grid-gutter * -2 0 $grid-gutter / 2;
      margin-right: $gutter-mobile;
      padding: 0;
      list-style: none;
      font-family: $font-family-header-light;

      @media screen and (min-width: $screen-sm) {
        display: block;
        margin-right: $gutter-desktop;
      }
      li {
        display: inline-block;
        &:before {
          content: '|';
          margin: 0 .5rem;
          color: $brand-primary;
        }
        &:first-child:before {
          display: none;
        }
      }
    }
  }

  [for="nav-checkbox"] {
    padding: $grid-gutter $gutter-mobile;
    font-family: $font-family-header-bold;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media screen and (min-width: $screen-sm) {
      padding: $grid-gutter $gutter-desktop;
    }

    &:hover,
    &:focus {
      color: $brand-primary;
    }

    i {
      margin-left: 1em;
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

.logo {
  svg {
    width: auto;
    height: $logo-size;

    @media screen and (min-width: $screen-sm) {
      height: $logo-size-desktop;
    }
  }

  .isvg.loaded {
    display: inline-block;
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.block-info {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  flex-basis: 26%;
  padding: 1em;
  margin: 5px;
  width: 155px;

  box-sizing: border-box;
  border-radius: $border-radius;
  &--normal {
    flex-grow: 1;
    border: $bg-secondary thin solid;
  }

  &--short {
    flex-basis: 33%;
  }

  span.text {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--mobile-1-2 {
    @media screen and (max-width: $screen-xs) {
      //smaller than 50% to make sure it has enough space for margin
      flex-basis: 34%;
    }
  }

  &--warning {
    flex-grow: 1;
    border: $brand-warning thin solid;
    &::after {
      font-family: FontAwesome;
      position: relative;
      left: 0.5rem;
      color: $brand-warning;
      content: prepend-slash($fa-var-warning);
    }
  }

  &:last-child {
    background-color: $bg-info-secondary;
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.linklist {
  list-style: none;
  margin: $grid-gutter * 2 0;
  padding: 0;

  &__link {
    a {
      position: relative;
      display: block;
      font-family: $font-family-header-semi;
      font-weight: $font-weight;
      padding-right: 1rem;
      cursor: pointer;

      &:before {
        @extend %icon;
        content: prepend-slash($fa-var-check-circle); //$icon-chevron-down;
        transform: rotate(-90deg);
        position: absolute;
        right: 0;
      }

      &:after {
        @include fa-icon();
        margin-left: $grid-gutter;
      }

      @media screen and (min-width: $screen-sm) {
        padding-right: 0;
        &:before {
          display: none;
        }
      }
    }

    &--valid {
      a {
        &:after {
          content: prepend-slash($fa-var-check-circle);
          color: $brand-success;
        }
      }
    }

    &--warning {
      a {
        &:after {
          content: prepend-slash($fa-var-exclamation-triangle);
          color: $brand-warning;
        }
      }
    }

    &--invalid {
      a {
        &:after {
          content: prepend-slash($fa-var-times-circle);
          color: $brand-danger;
        }
      }
    }
  }

  &--lined {
    > li {
      border-bottom: 1px solid $gray-light;
      margin-bottom: $grid-gutter * 2;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
}

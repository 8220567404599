@import "src/styles/variables";

.language-select {
  &__link {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &-selected {
      color: $brand-primary;
      text-decoration: none;
      border-bottom: solid 1.5px $brand-primary;
    }
  }
}
@import "src/styles/variables";

.reset-code {
  padding-top: 10vh;
  & fieldset {
    margin: 0 0 1.625rem;
  }

  & svg {
    margin-bottom: 2rem;
  }

  & .h1,
  & .h3,
  & .h5 {
    text-transform: none !important;
  }

  & form {
    margin: 0 auto;
    & fieldset {
      max-width: 350px;
      margin: 0 auto;
    }
  }

  & .text--error:before {
    display: none;
  }
  & .text--error {
    padding-left: 0;
  }

  & .reset-code__title {
    text-align: center;
    margin-bottom: 0;
    height: 3.5rem;
    text-transform: initial;

    &:after {
      content: none !important;
    }

    &.blue {
      color: $brand-primary;
    }

    &.fail {
      max-width: 415px;
      margin: 0 auto;
    }
  }

  & .reset-code__input {
    margin-top: 0 !important;
    margin-bottom: 2rem;

    & :after {
      bottom: 1rem;
    }
  }

  & .reset-code__button {
    display: block;
    margin: 0 auto;
  }

  & .reset-code__link {
    margin-top: 2.5rem;
    display: block;

    &:hover {
      color: $brand-primary;
    }
  }
}

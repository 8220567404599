@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.input {
  &-field {
    position: relative;

    &:after {
      @include fa-icon();
      position: absolute;
      bottom: 0.75rem;
      right: $grid-gutter; // for now;
      font-size: 1.15rem; // somehow height gets 1rem
      pointer-events: none;

      .container--dark & {
        right: $grid-gutter * 1.5;
        // bottom: 1rem;
      }
    }
    // mainly for phone number flag images
    img {
      display: block;
    }

    // used for password field so far
    .fa {
      padding: $grid-gutter 1rem;
      color: $brand-primary;
      font-size: $icon-size;
      cursor: pointer;
    }

    .fa,
    .tooltip {
      position: absolute;
      bottom: 0.25rem;
      right: -0.25rem; // for now
      transition: transform 0.3s ease;
    }
  }

  @media screen and (max-width: $screen-sm) {
    .fa,
    .tooltip {
      right: 2.25rem; // for now
    }
  }


  &-error {
    display: none;
    color: $brand-danger;
    .is-invalid & {
      display: block;
    }
  }

  .PhoneInputCountry{
    margin-top: 20px;
  }


  .phone-label {
    top:0;
  }

  label {
    position: absolute;
    left: 0;
    top: $font-size-smaller * -1;
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    transition: opacity 0.3s ease;
    color: $gray-light;

    .container--dark & {
      top: $font-size-smaller * -2;
    }
  }

  input {
    display: block;
    width: 100%;
    background: #fff;
    padding: $grid-gutter 0;
    margin-top: 1rem;
    // margin-top: $grid-gutter * 2;
    // margin-bottom: $grid-gutter;
    border: 1px solid $gray-light;
    border-width: 0 0 1px;
    box-sizing: border-box;
    box-shadow: none;
    appearance: none;
    font-size: $font-size-large;
    font-weight: $font-weight;
    transition: border-color 0.3s ease;

    &::placeholder {
      color: $gray-light;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus {
      border-color: $brand-primary;
      border-bottom-width: 2px;
      outline: 0;
    }

    &:disabled {
      opacity: 0.5;
    }

    &,
    &:placeholder-shown {
      + label {
        opacity: 0;
      }
    }

    // need to separate this from above css selector
    // otherwise, IE11 does not render correctly
    &.placeholder-shown {
      + label {
        opacity: 0;
      }
    }

    &:not(:placeholder-shown),
    &:not(.placeholder-shown):not(.rrui__input) {
      &:not(:focus) {
        border-color: #000;
        border-width: 0 0 1px;
      }

      + label {
        opacity: 1;
      }
    }

    .container--dark & {
      margin-top: 0;
      padding: $grid-gutter * 1.5;
      padding-bottom: ($grid-gutter - 0.25) * 1.5;
      border-bottom-width: 0.25rem !important;
      border-color: #fff;
      border-radius: $border-radius;

      &:not(:placeholder-shown),
      &:not(.placeholder-shown) {
        margin-top: $grid-gutter * 2;
        &:not(:focus) {
          border-color: #fff;
        }
      }

      + label {
        color: $brand-primary;
      }
    }
  }

  input[type="password"] {
    letter-spacing: $font-size / 4;
    &::placeholder {
      letter-spacing: normal;
    }
  }

  input[type="date"] {
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  //// phone number label overwrite
  .PhoneInput,
  .PhoneInput.placeholder-shown {
    .fa,
    .tooltip {
      top: $grid-gutter;
    }
    + label {
      top: 0;
      opacity: 0;
    }
  }

  .PhoneInput:not(.placeholder-shown) {
    input:not(:focus) {
      border-color: #000;
      border-width: 0 0 1px;
    }

    + label {
      opacity: 1;
    }
  }

  &.has-icon {
    input {
      padding-right: $grid-gutter * 4;
    }
  }

  &.is-invalid {
    .input-field:after {
      content: prepend-slash($fa-var-times-circle);
      color: $brand-danger;
    }
    input {
      &:focus {
        border-color: $brand-danger !important;
        // border-bottom-width: 2px !important;
      }
    }
  }

  &.is-valid.is-focused {
    .input-field:after {
      content: prepend-slash($fa-var-check-circle);
      color: $brand-success;
    }
    // input:focus {
    //   border-color: $brand-success !important;
    // }
    input:not(:focus) {
      border-color: #000;
      border-bottom-width: 1px;
      .container--dark & {
        border-color: #fff;
      }
    }
  }

  &.is-warning {
    .input-field:after {
      content: prepend-slash($fa-var-exclamation-triangle);
      color: $brand-warning;
    }
    input {
      border-color: $brand-warning !important;
    }
  }

  &.is-invalid,
  &.is-valid.is-focused,
  &.is-warning {
    input:focus {
      border-bottom-width: 2px;
    }
    .input-field .fa,
    .tooltip {
      transform: translateX(-2rem); // for now

      .container--dark & {
        transform: translateX(-3rem); // for now
      }
    }
  }

  &.is-disabled {
    .input-field {
      input,
      &:after {
        opacity: 0.5;
      }
    }
  }
}

// phone number overwrite
.rrui {
  &__input {
    &,
    &-label {
      height: auto;
    }
  }

  &__select {
    padding: $grid-gutter 0;
    margin-top: 1rem;
    &__button {
      border: none;
    }

    &__autocomplete,
    &--expanded {
      margin-top: 0 !important;
    }
  }
}

.evoApp {
  width: 150px;
  margin-right: 5px;
  align: right;
  display: inline-block;
}

.marginTop{
  margin-top: 45px;
}

.drivingRecordImg {
  max-width: 180%;
}

.underline {
  text-decoration: underline;
}

.dependon {
  height: 0;
  overflow: hidden;
  opacity: 0;
  &--active {
    height: auto;
    overflow: visible;
    opacity: 1;
    transition: opacity .3s ease .1s;
  }
}

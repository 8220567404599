@import "src/styles/mixins";
.reset-banner__container {
  margin: 0 auto;

  & .reset-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem auto;
    border: 1px solid #1ad6fc;
    background: transparent;
    border-radius: 8px;
    padding: 0.8rem;

    & svg {
      height: 35px;
      widows: 35px;
      margin: 0 0.8rem 0 0;
    }

    & div svg:last-child {
      padding: 0.5em;
      cursor: pointer;
    }

    & p {
      margin: 0;
    }
  }
}

.login {
  &__forgot-password {
    text-align: right;
  }

  &__button {
    text-transform: uppercase;
  }
}

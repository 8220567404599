@import "src/styles/variables";

.btn {
  text-transform: none;
  margin-left: 0px;
  margin-right: 0px;
}

.radius-tall {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  width: 263px;
  height: 275px;
  border: 1px solid $brand-primary;
  &[disabled],
  &[readonly] {
    //opacity: 0.4;
    cursor: default;
    & {
      background: $brandWhite !important;
      border: 2px solid gray;
    }
    &:before {
      background: $brandBlack !important;
      border: 2px solid gray;
    }
  }
}

.img-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.bikeAndCar-icon-hw {
  width: 237px;
  &[disabled] {
    filter: grayscale(1);
  }
}
.ebike-icon-hw {
  width: 139px;
  &[disabled] {
    filter: grayscale(1);
  }
}
.escooter-icon-hw {
  width: 71.43px;
  &[disabled] {
    filter: grayscale(1);
  }
}

.div-style {
  display: flex;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 640px;

  &[disabled],
  &[readonly] {
    opacity: 0.8;
    //  filter: alpha(opacity=0); /* msie */
  }
}
.radius {
  //font-size: $font-size-small;
  width: 230px;
  padding: 10px 0px;
  text-transform: none;
  margin-top: 35px;
  margin-bottom: 0px;
  font-family: $font-family-header;
  border: 1px solid $brand-primary;
  &[disabled] {
    background-color: $brandBlack;
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
}

.sub-text {
  color: $brand-primary;
  font-size: $font-size-medium;
  font-family: $font-family-header-regular;
  font-weight: $font-weight-regular;
  letter-spacing: normal;
  height: 15px;
  width: 100%;
  clear: both;
  margin-bottom: 10px;
  &[disabled] {
    color: $brandBlack;
    opacity: 1;
  }
}
.whiteText {
  color: $brandWhite;
}

.blackBackground-whiteBorder {
  background-color: $brandBlack;
  border: 1px solid $brandWhite;
}

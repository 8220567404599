@import "src/styles/variables";

.map {
  &__container {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (min-width: $screen-lg) {
      top: 115px;
    }

    @media screen and (max-width: $screen-lg) {
      top: 116px;
    }

    @media screen and (max-width: $screen-sm) {
      top: 64px;
    }
  }
}

// Style for TOP LEFT controller container
.controller-container {
  width: 120px;
  height: 50px;
  margin: 20px;
  & > .controller-icon {
    display: inline-block;
    margin: 6px;
    width: 44px;
    height: 44px;
    cursor: pointer;

    &_extended {
      border-radius: 0.2rem 0.2rem 0 0;
      background-color: white;
    }
  }

  .filters-container {
    display: block;
    width: 400px;
    border-radius: 0 0.2rem 0.2rem 0.2rem;
    margin: 6px;
    margin-top: -6px;
    padding: 1rem;
    background-color: white;
    font-family: $font-family-header-bold;

    &__filter {
      padding: 0.2rem 1rem;
      border-bottom: $bg-secondary 1px solid;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      & > .filter-input {
        margin: 0;
        & > label {
          padding-right: 0;
          &::before {
            position: relative;
          }
          &::after {
            top: 7px;
          }
        }
      }
    }
  }
}

// Style for TOP RIGHT search input bar
.map-search {
  display: flex;
  flex-grow: 1;
  width: 100%;
  padding: 20px;
  background-color: "transparent";

  &_term {
    border-radius: 40px 0 0 40px;
    border-width: 0;
    color: black;
    float: left;
    width: 250px;
    font-size: $font-size-base;
    line-height: 44px;
    outline: none;
    padding: 5px 20px;
    animation-name: shrink-input;
  }

  &_button {
    width: 100px;
    padding: 5px 10px;
    height: 54px;
    background: #02b8de;
    text-align: center;
    color: #fff;
    border-radius: 0 40px 40px 0;
    font-size: $font-size-base;
    cursor: pointer;
    & a {
      color: white;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.map__banner {
  opacity: 0.9;
  margin-top: 100px;
}

.iw_container-close {
  position: absolute;
  top: 15px;
  right: 15px;
  &:hover {
    cursor: pointer;
  }
}

// Google Map stuff
.pac-container {
  display: block;
  border-radius: 5px;
  background-color: white;
  margin: 0;
  width: 100% !important;
  &:after {
    content: none !important;
  }
  .pac-icon {
    display: none !important;
  }

  .pac-item {
    padding: 5px;
  }
}

.cluster {
  & > span {
    background-color: white;
    position: absolute;
    top: -12px;
    width: 24px;
    height: 24px;
    border: black thin solid;
    border-radius: 12px;
    color: black;
    line-height: 22px;
    text-align: center;
  }
}

.parking-iw-item-container {
  display: flex;
  align-items: center;
  & img {
    flex: 1;
  }
  & span,
  div {
    flex: 7;
  }
}

// Override Google map styels
.gm-style-iw,
.gm-style-iw-t::after {
  margin-top: -14px;
  overflow: visible !important;
}

.gm-style-iw-c button {
  display: none !important;
}

@import "src/styles/variables";

.parking-marker {
  cursor: pointer;
  position: relative;
  margin-left: -13px;
  margin-top: -13px;
  z-index: 9;
  & svg {
    z-index: 9;
  }

  &_on-top {
    z-index: 1000001;
  }
}

.parking-details {
  z-index: 10;
  box-sizing: content-box;
  padding: 22px;
  width: 280px;
  background: white;
  position: relative;
  transform: translate(-50%, -110%);
  border-radius: 0.5rem;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);

  &_on-top {
    z-index: 1000000;
  }

  &__brief {
    display: block;
    border-bottom: thin solid lightgrey;
    padding: 10px 0 20px 0;
    font-size: $font-size-small;
    flex: 1;

    &__title {
      font-weight: $font-weight-bold;
    }

    &__pin,
    &__distance {
      display: flex;
      align-items: center;
      font-weight: $font-weight-semi;
      margin: 10px 0 5px 0;
      color: $brand-primary;

      & > img {
        margin-right: 0.5rem;
      }
    }
  }

  &_directions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    margin: 10px 0;
    font-size: $font-size-large;
    color: $brand-primary;
  }

  &__notes {
    display: block;
    padding-top: 20px;
    flex: 1;

    & > div {
      display: flex;
      align-items: flex-start;
      font-size: $font-size-small;
      margin-bottom: 0.5rem;

      & > img {
        margin: 0 0.7rem;
      }

      & > span {
        margin-top: 3px;
      }
    }

    &__space {
      // & div {
      //   padding-left: 1px;
      // }
    }
    &__instructions {
      & span > ul {
        padding-inline-start: 1rem;
        margin: 0;

        & > li > span {
          position: relative;
          left: -0.5rem;
        }
      }
      // margin-top: 10px;
      // & ul {
      //   margin-top: 0;
      //   padding-left: 0.5rem;
      //   & li {
      //     margin-bottom: 5px;
      //   }
      // }
      // & div {
      //   padding-top: 5px;
      //   padding-left: 1px;
      // }
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";

// WE MIGHT NOT DO NAV
#nav-checkbox {
  position: fixed;
  left: -9999em;
}
[for="nav-checkbox"] {
  cursor: pointer;
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  background: #000;
  color: #fff;
  width: 16rem; // for now
  height: 100vh;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  padding: $grid-gutter;
  z-index: 10; // nav cover is 9

  &__inner {
    position: relative;
    height: 100%;
    .spacer {
      padding-top: 15px;
    }
  }

  @media screen and (min-width: $screen-sm) {
    padding: $grid-gutter * 2;
  }

  [for="nav-checkbox"] {
    &:before {
      @extend %icon;
      content: "+";
      font-weight: $font-weight-bold;
      font-size: 1.5rem;
      margin-top: -0.5rem;
      transform: rotate(45deg);
    }
  }

  footer {
    position: absolute;
    bottom: $grid-gutter;
    svg path {
      fill: #fff;
    }
  }

  label {
    color: #fff;
    right: 1rem;
    position: absolute;
    z-index: 9;

    &:hover {
      color: $brand-primary;
    }
  }
}

#nav-checkbox:checked ~ .nav {
  transform: translateX(0);
}

// font file path
$fontPath: "/assets/fonts/" !default; // fallback must be overwritten by webpack

//== Colors
$brand-primary: #00bce2; //#02A1E0; // light blue
$brand-success: #7ed321;
$brand-info: #00bce2;
$brand-info-light: #e6f8fd;
$brand-warning: #f0ad4e;
$brand-warning-light: #fff1d9;
$brand-danger: #d0021b;
$brand-danger-light: #fbeced;
$brand-normal: #474747;

$bg-primary: #f2fbfd; // light blue
$bg-secondary: #e8e8e8; // light gray
$bg-secondary-lighter: #f7f7f7; // light gray
$bg-info: #f6f6f6; // light blue gray
$bg-info-secondary: #f2f2f2;
$bg-info-darker: #EAEAEA;
$bg-table-single: #f4fbfd;
$brandBlack:                #000000;
$brandWhite:                #ffffff;
$brandGray01:               #838383;

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 25%); // #404040
$gray: lighten($gray-base, 40%); // #666
$gray-light: lighten($gray-base, 61%); // #9c9c9c
$gray-lighter: lighten($gray-base, 80%); // #ccc

$gray-blue: #7c86a2; // WATCH OUT: used only on calendar

$body-bg: #fff;
$font-color: $gray-dark;
$link-color: $brand-primary;
$line-height: 1.3334; // 20/14
$line-height-header: 1.1; // mockup number is 1

//== Type
$font-size: 16px;
$font-unit: 0rem; // Pick em or rem here

$font-size-base: 1 + $font-unit; /* 16px */
$font-size-large: 1.125 + $font-unit; /* 18px */
$font-size-medium: 0.9375 + $font-unit; /* 15px */
$font-size-small: 0.875 + $font-unit; /* 14px */
$font-size-smaller: 0.75 + $font-unit; /* 12px */

$font-weight-ultralight: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight: $font-weight-regular;

// font family
$font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;

$font-family-header-ultralight: "perolight", sans-serif;
$font-family-header-light: "perosemilight", sans-serif;
$font-family-header-regular: "peroregular", sans-serif;
$font-family-header-semi: "perosemibold", sans-serif;
$font-family-header-bold: "perobold", sans-serif;

$font-family-header: $font-family-header-bold;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

//== Scaffolding
$grid-gutter: calc(10px / $font-size) + $font-unit;
// gutter on both sides
$gutter-mobile: $grid-gutter * 2;
$gutter-desktop: $grid-gutter * 4;

$screen-xxs: calc(350px / $font-size) + $font-unit; // to exclude iPhone5 type 320px
$screen-xs: calc(480px / $font-size) + $font-unit;
$screen-sm: calc(768px / $font-size) + $font-unit;
$screen-md: calc(1024px / $font-size) + $font-unit;
$screen-lg: calc(1230px / $font-size) + $font-unit;

// media query @media screen and (min-width: $screen-sm) { ... }
$viewport-xxs: screen and (min-width: $screen-xxs);
$viewport-xs: screen and (min-width: $screen-xs);
$viewport-sm: screen and (min-width: $screen-sm);
$viewport-md: screen and (min-width: $screen-md);
$viewport-lg: screen and (min-width: $screen-lg);

$border-radius: 3px;
$avatar-size: $grid-gutter * 2.5;

// header area - used for main top margin
// use JS to find this if this doesn't work
$logo-size: 2.75rem;
$logo-size-desktop: 5rem;
$header-height: $grid-gutter * 1.5 + $logo-size + 0.3;
$header-height-desktop: $grid-gutter * 3 + $logo-size-desktop + 0.3;
$header-sub-height: $header-height + $grid-gutter * (1.5 + 2) + $font-size-small * $line-height;
$header-sub-height-desktop: $header-height-desktop + $grid-gutter * (3 + 2) + $font-size-base * $line-height;

// font-awesome doesn't render in 16px
$icon-size: 1.175rem;

// normalize-scss _variables
$base-font-size: $font-size;
$base-line-height: $font-size * $line-height;
$base-unit: "rem";
$base-font-family: $font-family;

$h1-font-size: $font-size * 1.5;
$h2-font-size: $font-size * 1.25;
$h3-font-size: $font-size * 1.125;
$h4-font-size: $font-size * 1;
$h5-font-size: $font-size * 0.875;

$h1-font-size-desktop: $font-size * 2.25;
$h2-font-size-desktop: $h2-font-size;
$h3-font-size-desktop: $h3-font-size;
$h4-font-size-desktop: $h4-font-size;
$h5-font-size-desktop: $h5-font-size;

// font-awesome overwrite
// $fa-font-path: "../assets/font-awesome/fonts";
$fa-font-size-base: $font-size-base;

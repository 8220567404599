.image-preview {
  position: relative;
  text-align: center;
}

.image-preview > img {
  max-width: 550px;
  max-height: 340px;
}

@media(max-width:768px){
  .image-preview > img {
    max-width: 550px;
    max-height: 340px;
  }
}

/* fullscreen enable by props */
.image-preview-fullscreen > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

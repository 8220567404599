@charset "UTF-8";
@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";
//@import "../../styles/favariables";

$tooltipicon: $icon-size + 2;
.checkbox {
  // white-space: nowrap;
  margin: $grid-gutter 0;

  input[type="checkbox"] {
    // WATCH OUT
    position: absolute;
    left: -100vw;

    // Focus for radio, and checkbox - should have for file field too
    &:focus {
      @include tab-focus;
    }

    &:disabled {
      + label {
        cursor: default;
      }
    }

    + label {
      position: relative;
      padding: $grid-gutter / 4 $grid-gutter;
      padding-left: $grid-gutter * 4;
      margin: 0;
      display: inline-block;
      font-weight: $font-weight;
      cursor: pointer;

      .tooltip-container {
        margin-right: 16px;
      }

      .tooltip {
        &:before {
          padding: 0;
          padding-left: $grid-gutter/2;
        }
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 0;
        content: "";
        display: inline-block;
        transition: all 0.2s ease;
        transform: translateY(-50%);
        width: 1.5rem;
        text-align: center;
      }

      &:before {
        background: #fff;
        height: 1.5rem;
        border-radius: $border-radius;
        border: 1px solid $gray-light;
      }

      &:after {
        @include fa-icon();
        left: 1px;
        color: $brand-primary;
      }
    }

    &:checked {
      + label {
        &:after {
          content: prepend-slash($fa-var-check);
        }
      }
    }

    &:disabled {
      + label::before {
        opacity: 0.6;
      }
    }
  }
&--large-font {
  input[type="checkbox"] {
    + label {
      //padding: $grid-gutter / 2 $grid-gutter * 6 $grid-gutter / 2 0;
      font-size: $font-size-large;

    }
  }
}
  // toggle type
  &--toggle {
    input[type="checkbox"] {
      + label {
        // min-width: calc(100% - #{$tooltipicon});
        padding: $grid-gutter / 2 $grid-gutter * 6 $grid-gutter / 2 0;
        font-size: $font-size-large;

        &:before,
        &:after {
          content: "" !important;
          left: auto;
          right: 0;
          top: 0;
          transform: none;
        }
        &:before {
          width: $grid-gutter * 5;
          height: $grid-gutter * 3;
          border-radius: $grid-gutter * 3;
          background: $bg-secondary;
          border: 2px solid $bg-secondary;
        }

        &:after {
          top: 2px;
          right: 2px;
          width: $grid-gutter * 3;
          height: $grid-gutter * 3;
          border-radius: 50%;
          background: #fff;
          transform: translateX($grid-gutter * -2);
          box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
        }
      }

      &:checked {
        + label {
          &:before {
            background: $brand-primary;
            border-color: $brand-primary;
          }

          &:after {
            transform: translateX(0);
          }
        }
      }
    }

    &--setWidth {
      input[type="checkbox"] {
        + label {
          padding: $grid-gutter / 2 $grid-gutter * 6 $grid-gutter / 2 0;
          font-size: $font-size-large;

          &:before,
          &:after {
            content: "" !important;
            left: auto;
            right: 0;
            top: 0;
            transform: none;
          }

          &:before {
            width: $grid-gutter * 5;
            height: $grid-gutter * 3;
            border-radius: $grid-gutter * 3;
            background: $bg-secondary;
            border: 2px solid $bg-secondary;
          }

          &:after {
            top: 2px;
            right: 2px;
            width: $grid-gutter * 3;
            height: $grid-gutter * 3;
            border-radius: 50%;
            background: #fff;
            transform: translateX($grid-gutter * -2);
            box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
          }
        }
        &:checked {
          + label {
            &:before {
              background: $brand-primary;
              border-color: $brand-primary;
            }

            &:after {
              transform: translateX(0);
            }
          }
        }
      }
    }



    // toggle left theme
    &--left {
      input[type="checkbox"] {
        + label {
          padding: $grid-gutter / 2 $grid-gutter * 6 $grid-gutter / 2 $grid-gutter * 7;
          min-width: 100%;

          &:before {
            left: 0;
          }
          &:after {
            left: 24px;
          }
        }
      }
    }
  }
}


@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

$icon-chevron-down: "ea01";
$icon-plus: "ea02";

@font-face {
    font-family: icon;
    src: url("#{$fontPath}icon.eot");
    src: url("#{$fontPath}icon.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}icon.woff2") format("woff2"),
    url("#{$fontPath}icon.woff") format("woff"),
    url("#{$fontPath}icon.ttf") format("truetype"),
    url("#{$fontPath}icon.svg#icon") format("svg");
    font-style: normal;
    font-weight: 400;
}

%icon {
    display: inline-block;
    transform: translate(0, 0);
    text-rendering: auto;
    font: normal normal 400 1rem/1 icon;
    font-size: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.icon {
    @extend %icon;
}

.icon-chevron-down::before {
    content: "ea01";
}

.icon-plus::before {
    content: "ea02";
}

@charset "UTF-8";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.list {
  padding-left: 0;
  margin: 0 0 $grid-gutter * 2;
  width: 100%;

  > li {
    margin: 0 $grid-gutter + 1 $grid-gutter;
    margin-right: 0;
    padding-left: $grid-gutter;

  }

  &--smaller {
    font-size: $font-size-smaller;
  }

  &--small {
    font-size: $font-size-small;
  }

  &--large {
    font-size: $font-size-large;
  }

  &--base {
    font-size: $font-size-base;
  }

  &.list--blue-check {
    > li {
      list-style: none; /* removes default bullet point */
     // padding-left: 0.0em; /* moves items left */

      &:before {
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 2.3em; /* space between text and icon */
        @include fa-icon();
        color: $brand-primary;
        content: prepend-slash($fa-var-check);
      }
    }

  }

  &.list--plain {
    list-style: none;

    > li {
      margin: 0;
      padding: $grid-gutter;

    }

    &.list--plain-sub {
      margin: 0;
      padding: 0;

      > li {
        padding: $grid-gutter/4 $grid-gutter*8 0 $grid-gutter;
      }
    }

    &.list--plain-underlined {
      > li {
        border-bottom: thin dashed $gray-lighter;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  @media screen and (min-width: $screen-sm) {
    &--col2 {
      columns: 2;
    }

    &--col3 {
      columns: 3;
    }
  }
}

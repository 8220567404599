@charset "UTF-8";

@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.alert {
  &--success {
    background-color: $brand-info-light;
  }

  &--warning {
    background-color: $brand-warning-light;
  }

  &--error {
    background-color: $brand-danger-light;
  }

  .alert__content {
    @include container();
    padding: 1rem;
    display: flex;

    &::before {
      display: inline-block;
      @extend %icon;
      font-family: FontAwesome;
      vertical-align: middle;
      margin-right: 1rem;
      margin-top: 0.2rem;
    }

    &__body {
      flex-grow: 1;
    }

    &--compact {
      padding: 1rem !important;
    }

    &--success {
      &::before {
        content: prepend-slash($fa-var-check-circle);
        color: $brand-primary;
      }
    }

    &--warning {
      &::before {
        content: prepend-slash($fa-var-exclamation-triangle);
        color: $brand-warning;
      }
    }

    &--error {
      &::before {
        content: prepend-slash($fa-var-times-circle);
        color: $brand-danger;
      }
    }

    & > i {
      cursor: pointer;
      margin-left: 1rem;
      margin-top: 0.2rem;
    }

    > span > span > a,
    > span > a {
      color: $gray-darker;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

.intro {
  .btn {
    margin-top: $grid-gutter;
    margin-bottom: $grid-gutter;
  }

  &__img {
    margin-bottom: 0;
    img {
      margin: auto;
      max-width: 240px;

      @media screen and (min-width: $screen-sm) {
        max-width: 350px;
      }
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";


.outerForm {
  .backButton {
    margin-left: -35px;
    float: left;

    @media (max-width: 768px) {
      margin-left: 0;
      float: none;
      margin-bottom: 10px;
    }
  }


  .form {
    &__section {
      margin-bottom: $grid-gutter * 4;
    }

  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.btn {
  position: relative;
  display: inline-block;
  background: $brand-primary;
  padding: 3px $grid-gutter * 2;
  margin: 0 $grid-gutter $grid-gutter;
  margin-left: 0;
  border: none;
  border-radius: $grid-gutter + 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: $grid-gutter + 1;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  & :nth-child(n) {
    vertical-align: middle;
  }

  &--full-width {
    width: 100%;
    text-align: center;
  }

  &:not(.btn--cta) {
    min-width: 10rem; // WATCH OUT
  }

  &--dark {
    background-color: $brandBlack;
    color: $brandWhite;
  }

  @media screen and (min-width: $screen-sm) {
   // margin-bottom: 0;
  }

  &[disabled],
  &[readonly] {
    opacity: 0.5;
    cursor: default;
    & {
      background: $gray !important;
    }
    &:before {
      background: #000 !important;
    }
  }

    &--cta {
    padding-left: 3px; // for icon
    text-align: inherit;
    &:before {
      content: "";
      width: $grid-gutter + 1;
      height: $grid-gutter + 1;
      background: #000;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle; // WATCH OUT
      margin-right: 0.5rem;
      transition: all 0.3s ease;
    }

    &:after {
      @include fa-icon();
      content: prepend-slash($fa-var-arrow-right);
      position: absolute;
      left: 0.6rem;
      top: 50%;
      transform: translateY(-50%);
    }

    > span {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 2.3rem);
      white-space: nowrap; // WATCH OUT
      text-align: center;
    }
  }

  &--loading {
    img {
      max-width: 1em;
    }
    &--overlap {
      cursor: default;
      outline: 0;
      &:before,
      &:after,
      & > .btn__content {
        opacity: 0;
      }
      & > .btn__loading-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:focus,
      &:hover {
        background: $brand-primary !important;
      }
    }
    &--inline {
      & > .btn__loading-icon {
        display: inline-block;
      }
    }
  }

  &--link {
    color: $brand-primary;
    letter-spacing: initial;
    border: none;
    background: transparent;
    &:hover,
    &:focus {
      text-decoration: underline !important;
      border: none;
      background: transparent !important;
    }
  }

  &--link-no-underline {
    color: $brand-primary;
    letter-spacing: initial;
    border: none;
    background: transparent;
    &:hover,
    &:focus {
      text-decoration: none !important;
      border: none;
      background: transparent !important;
    }
  }

  &--compact {
    min-width: 6rem !important;
    padding: initial !important;
    margin: initial !important;
  }

  &:hover,
  &--inverse {
    background: #000;
    text-decoration: none;
    &:before {
      background: $brand-primary;
    }
    &:after {
      color: #fff;
    }
  }

  .container--dark & {
    &:hover,
    &:focus {
      background: #fff;
      color: $brand-primary;
      &:before {
        background: #000;
      }
      &:after {
        color: $brand-primary;
      }
    }

    &--inverse {
      border: 1px solid #fff;
      // &:hover,
      // &:focus {
      //   background: $brand-primary;
      //   color: #fff;
      //
      //   &:after {
      //     color: #fff;
      //   }
      // }
    }

    &[disabled],
    &[readonly] {
      &,
      &:before {
        background: #fff !important;
      }
      &,
      &:after {
        color: #000;
      }
    }
  }

  &--inverse {
    &:hover,
    &:focus {
      background: $brand-primary;
      color: #fff;

      &:before {
        background: #000;
      }
    }
  }

  &--large {
    font-size: $font-size-large;
    padding: 0.75rem $grid-gutter * 4;
    width: 100%;
    &:not(.btn--cta) {
      min-width: 200px;
    }

    &.btn--cta {
      padding-left: $grid-gutter;
      &:after {
        left: 1rem;
      }
    }

    @media screen and (min-width: $screen-sm) {
      width: auto;
    }
  }

  &__icon-container {
    float: right;
    margin-right: -1rem;

    i.primary {
      color: $brand-primary;
    }
  }

  &--bold {
    font-weight: $font-weight-bolder;
    font-family: $font-family-header-bold;
  }
}

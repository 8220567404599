@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.nav-linklist {
  list-style: none;
  padding: 0;
  list-style: none;
  color: white;
  margin: 0 1rem;

  &__link {
    color: white;
    position: relative;
    display: block;
    font-family: $font-family-header-semi;
    font-weight: $font-weight;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $brand-primary;
      text-decoration: none;

      &::before {
        position: absolute;
        left: -1rem;
        font-family: FontAwesome;
        content:prepend-slash($fa-var-angle-right);
      }
    }
  }

  &__sub-list__title::after {
    font-family: FontAwesome;
    position: relative;
    left: 0.5rem;
    content:prepend-slash($fa-var-angle-down);
  }

  &__sub-list {
    list-style: none;
    padding-left: 1rem;

    &__link {
      font-size: $font-size-small;
      color: $gray-light;
      text-decoration: none;

      &:hover {
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

@mixin gridvariation($min: '') {
  &-1-2#{$min} { width: percentage(1/2); }
  &-2-3#{$min} { width: percentage(2/3); }
}

.grid {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;

  &--center {
    justify-content: center; /* center items horizontally */
    text-align: center;
  }
  &--middle {
    align-items: center;     /* center items vertically */
  }
  &--div {
    padding-top: $grid-gutter * 2;
    padding-bottom: $grid-gutter * 2;
  }
  &--small-div {
    padding-top: $grid-gutter;
    padding-bottom: $grid-gutter;
  }

  @include gridvariation();

  @media screen and (min-width: $screen-sm) {
    @include gridvariation('--sm');
  }

  @media screen and (min-width: $screen-md) {
    @include gridvariation('--md');
  }

  @media screen and (min-width: $screen-lg) {
    @include gridvariation('--lg');
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

small {
  &.text {
    display: block;
    margin: $grid-gutter 0;
  }
}

.text {
  &:empty {
    display: none !important;
  }
  &--primary {
    color: $brand-primary;
  }

  &--success {
    color: $brand-success;
  }

  &--normal {
    color: $brand-normal;
  }

  &--error {
    color: $brand-danger;

    .container--dark & {
      position: relative;
      padding-left: $icon-size + 0.5;
      color: #fff;

      &:before {
        @include fa-icon();
        content: prepend-slash($fa-var-times-circle);
        vertical-align: middle;
        margin-right: 0.5rem;
        margin-left: ($icon-size + 0.5) * -1;
        background: #fff;
        border-radius: 50%;
        box-shadow: inset 0 0 5px #000;
        color: $brand-danger;
        font-size: $icon-size;
        line-height: 75%;
      }
    }
  }

  &--note {
    color: $gray-light;
  }

  &--dagger {
    color: $gray-light;
    &:before {
      content: "\2021"; //"‡"; // assuming double dagger
      margin-right: 0.5em;
      display: inline-block;
      vertical-align: middle;
      color: $brand-primary;
      font-size: $font-size-smaller;
    }
  }
  &--daggerlabel {
    &:after {
      content: "\2021"; //"‡"; // assuming double dagger
      margin: auto 0.5em;
      display: inline-block;
      vertical-align: middle;
      color: $brand-primary;
      font-size: $font-size-smaller;
    }
  }

  &--vertical-gutter {
    margin-top: $grid-gutter;
    margin-bottom: $grid-gutter;
  }

  &--right-gutter {
    margin-right: $grid-gutter*2;
  }

  &--bottom-gutter {
    display: block;
    margin-bottom: $grid-gutter*4;
  }

  // use small tag
  &--smaller {
    font-size: $font-size-smaller;
  }

  &--small {
    font-size: $font-size-small;
  }

  &--large {
    font-size: $font-size-large;
  }

  &--larger {
    font-size: $h1-font-size;
  }

  &--header {
    font-family: $font-family-header-regular;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--bolder {
    font-weight: $font-weight-bolder;
  }

  // positioning
  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--float-right {
    float: right;
  }

  &--middle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &--upper {
    text-transform: uppercase;
  }

  &--lower {
    text-transform: lowercase;
  }
}

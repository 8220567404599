@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.card {
  margin: 1rem 0;
  border: thin solid $gray-lighter;
  border-radius: 4px;

  & > :first-child {
    border-radius: 4px 4px 0 0;
  }

  & > :last-child {
    border-radius: 0 0 4px 4px;
  }

  &--bg-light-gray {
    background-color: $bg-secondary-lighter;
  }

  &--bg-primary {
    background-color: $bg-primary;
  }

  &--border-primary {
    border: 3px solid $brand-primary;
    padding: 5px;
  }

  &--small {
    width: 80%;
  }

  &__body {
    &--top-dashed {
      & > * {
        box-sizing: border-box;
        border-top: thin dashed $gray-lighter;
      }
    }
  }

  &__footer {
    border-top: thin solid $gray-lighter;

    &--single-action {
      text-align: center;
      cursor: pointer;
      align-item: center;
      color: $brand-primary;
      border: $brand-primary thin solid;
      border-radius: 0 0 4px 4px;
      margin: 0 -1px -1px -1px;
    }
  }
}

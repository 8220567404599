@import "src/styles/variables";
@import "src/styles/mixins";

.minutes-landing {
  &__apply-promo-code {
    order: -1;
    @media screen and (min-width: $screen-sm) {
      order: 2;
    }
  }
}

@charset "UTF-8";
@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";
@import "variables";

div.value-selected.react-select__control {
  border-color: $brandBlack;
  border-width: 0 0 1px;

  &:hover {
    border-color: $brandBlack;
    border-width: 0 0 1px;
  }

  &--is-focused {
    box-shadow: none;
    border-color: $brand-primary;
    border-bottom-width: 2px;
    outline: 0;

    &:hover {
      box-shadow: none;
      border-color: $brand-primary;
      border-bottom-width: 2px;
      outline: 0;
    }

  }

  &--is-disabled {
    background-color: white;
    border-color: #9c9c9c;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: $font-size-large;
    color: pink;
  }
}



.dropdown-label {
  &,
  &:not(.placeholder-shown) {
    opacity: 1;
  }

  &,
  &:placeholder-shown {
    opacity: 0;
  }

  // need to separate this from above css selector
  // otherwise, IE11 does not render correctly
  &.placeholder-shown {
    opacity: 0;
  }
}

.dropdown {
  position: relative;

  &-field {
    position: relative;


    label {
      position: absolute;
      left: 0;
      top: $font-size-smaller * -1;
      font-size: $font-size-smaller;
      font-weight: $font-weight-bold;
      transition: opacity 0.3s ease;
      color: $gray-light;

    }

    &:before,
    &:after {
      position: absolute;
      bottom: 1rem;
      pointer-events: none;
    }

    &:before {
      @extend %icon;
      content: prepend-slash($icon-chevron-down);
      right: $grid-gutter;
      bottom: 1.125rem;
      font-size: $font-size-small;
      color: $brand-primary;
      transition: transform .3s ease;

      .container--dark & {
        bottom: 1.25rem;
      }
    }

    &:after {
      @include fa-icon();
      right: $grid-gutter * 2;
      bottom: .75rem;
      font-size: 1.15rem; // somehow height gets 1rem

      .container--dark & {
        right: $grid-gutter * 1.5;
      }
    }
  }

  select,
  .Select {
    font-size: $font-size-large;

    + label {
      position: absolute;
      top: $font-size-smaller * -1;
      opacity: 0;
      font-size: $font-size-smaller;
      font-weight: $font-weight-bold;
      color: $gray-light;
      transition: opacity .3s ease;

      .container--dark & {
        top: $font-size-smaller * -2;
      }
    }

    &.has-value {
      // margin-top: $grid-gutter * 2;
      color: $font-color;

      &:not(.is-focused) {
        .Select-control {
          border-color: #000;
          border-width: 0 0 1px;

          .container--dark & {
            border-color: #fff;
          }
        }
      }

      &:not(:focus) {
        border-color: #000;
        border-width: 0 0 1px;
      }

      + label {
        opacity: 1;
      }
    }

    .container--dark & {
      + label {
        color: $brand-primary;
      }

      &.has-value {
        margin-top: $grid-gutter * 2;
      }
    }
  }

  select {
    display: block;
    width: 100%;
    background: #fff;
    padding: $grid-gutter 0;
    padding-right: $grid-gutter * 2;
    margin-top: 1rem;
    // margin-bottom: $grid-gutter;
    border: 1px solid $gray-light;
    border-width: 0 0 1px;
    border-radius: 0;
    box-sizing: border-box;
    appearance: none;
    font-weight: $font-weight;
    transition: border-color .3s ease;
    color: $gray-lighter;
    // for ie11
    &::-ms-expand {
      display: none;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus {
      border-color: $brand-primary;
      border-bottom-width: 2px;
      outline: 0;
    }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      border: 0;
      background-color: transparent;
    }

    .container--dark & {
      padding: $grid-gutter * 1.5;
      padding-bottom: ($grid-gutter - .25) * 1.5;
      border-bottom-width: .25rem !important;
      border-color: #fff;
      border-radius: $border-radius;

      &.has-value {
        &:not(:focus) {
          border-color: #fff;
        }
      }
    }
  }

  &.is-invalid {
    .dropdown-field:after {
      content: prepend-slash($fa-var-times-circle);
      color: $brand-danger;
      padding-right: 10px;
      padding-bottom: 2px
    }

    select,
    .Select-control {
      border-color: $brand-danger !important;
      border-bottom-width: 2px !important;

      .container--dark & {
        border-bottom-width: .25rem !important;
      }
    }
  }

  &.is-valid.is-focused {
    .dropdown-field:after {
      content: prepend-slash($fa-var-check-circle);
      color: $brand-success;
      padding-right: 10px;
      padding-bottom: 2px

    }

    select:focus,
    .Select.is-focused .Select-control {
      border-color: $brand-success !important;
    }

    select:not(:focus),
    .Select:not(.is-focused) .Select-control {
      border-color: #000;
      border-bottom-width: 1px !important;

      .container--dark & {
        border-color: #fff;
      }
    }
  }

  &.is-warning {
    .dropdown-field:after {
      content: prepend-slash($fa-var-exclamation-triangle);
      color: $brand-warning;
    }

    select,
    .Select-control {
      border-color: $brand-warning !important;
    }
  }


  &.is-invalid,
  &.is-valid.is-focused,
  &.is-warning {
    select,
    .Select:not(.is-focused) .Select-control {
      border-bottom-width: 2px;
    }

    .dropdown-field:after,
    .tooltip {
      transform: translateX(-1rem); // for now

      .container--dark & {
        transform: translateX(-2rem); // for now
      }
    }
  }

  &.is-disabled {
    .dropdown-field {
      select,
      &:before,
      &:after {
        opacity: .5;
      }

      &:before {
        color: $gray-light;
      }
    }
  }


  &.is-invalid .react-select__control--is-focused{
    border-color: $brand-danger !important;
    border-bottom-width: 2px !important;
  }

  // react-select overwrite
  .react-select {

    &__placeholder {
      color: $gray-light;
    }

    &__indicator-separator {
      display: none;
    }

    &__clear-indicator {
      margin-left: 2px;
      padding: 0;
    }

    &__dropdown-indicator {
      color: $brand-primary;
      padding: 0;
    }

    &__single-value {
      &--is-disabled {
        color: #aaaaaa;
      }
    }

    &__value-container {
      height: 44px;
      padding-right: 2.5rem;
      padding-left: 0;
    }

    &__control {

      .value-selected {
        border-color: orange;
      }

      // .react-select__indicators {
      //   &::after {
      //     content: prepend-slash($fa-var-chevron-down);
      //     color: $brand-primary;
      //   }
      // }

      // .react-select__dropdown-indicator {
      //   display: none;
      // }

      border-width: 0 0 1px;
      margin-top: 1rem;
      // margin-bottom: $grid-gutter;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: $font-size-large;
      border-radius: 0;
      border-color: $gray-light;

      &:hover {
        border-color: $gray-light;;
      }

      &--is-focused {
        box-shadow: none;
        border-color: $brand-primary;
        border-bottom-width: 2px;
        outline: 0;
      }

      &--is-disabled {
        background-color: white;
        border-color: #9c9c9c;
        font-family: $font-family;
        font-weight: $font-weight-regular;
        font-size: $font-size-large;
        color: pink;
      }

    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

@mixin colvariations($min: '') {
  &-1-12#{$min} {
    max-width: percentage(1/12);
    flex-basis: percentage(1/12);
  }

  &-1-6#{$min} {
    max-width: percentage(1/6);
    flex-basis: percentage(1/6);
  }

  &-1-5#{$min} {
    max-width: percentage(1/5);
    flex-basis: percentage(1/5);
  }

  &-1-4#{$min} {
    max-width: percentage(1/4);
    flex-basis: percentage(1/4);
  }

  &-1-3#{$min} {
    max-width: percentage(1/3);
    flex-basis: percentage(1/3);
  }

  &-1-2#{$min} {
    max-width: percentage(1/2);
    flex-basis: percentage(1/2);
  }

  &-2-5#{$min} {
    max-width: percentage(2/5);
    flex-basis: percentage(2/5);
  }

  &-2-3#{$min} {
    max-width: percentage(2/3);
    flex-basis: percentage(2/3);
  }


  &-3-5#{$min} {
    max-width: percentage(3/5);
    flex-basis: percentage(3/5);
  }

  &-3-4#{$min} {
    max-width: percentage(3/4);
    flex-basis: percentage(3/4);
  }

  &-4-5#{$min} {
    max-width: percentage(4/5);
    flex-basis: percentage(4/5);
  }

  &-5-6#{$min} {
    max-width: percentage(5/6);
    flex-basis: percentage(5/6);
  }
}

.col {
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100%;
  flex-basis: 100%;
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;

  &--right{
    text-align: right;
  }

  @include colvariations();

  @media screen and (min-width: $screen-sm) {
    @include colvariations('--sm');
  }
  @media screen and (min-width: $screen-md) {
    @include colvariations('--md');
  }
  @media screen and (min-width: $screen-lg) {
    @include colvariations('--lg');
  }
}

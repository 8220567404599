@import "src/styles/variables";
@import "src/styles/mixins";

@mixin sectionvariation($min: "") {
  &-1-2#{$min} {
    width: percentage(1/2);
  }
  &-1-3#{$min} {
    width: percentage(1/3);
  }
  &-2-3#{$min} {
    width: percentage(2/3);
  }
}

// section exists only to remove the grid__col both paddings
.section {
  margin: 0 $grid-gutter * -1;
  @include sectionvariation();

  // gray background box
  &--info {
    background: $bg-info;
    padding: $grid-gutter;

    border-radius: $border-radius;

    @media screen and (min-width: $screen-sm) {
      padding: $grid-gutter * 2 $grid-gutter * 3;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--child {
    @media screen and (min-width: $screen-sm) {
      padding-left: $grid-gutter * 4;
    }
  }

  // add space separation
  &--div {
    margin-top: $grid-gutter * 2;
    margin-bottom: $grid-gutter * 2;
  }

  &--vertial-padding {
    padding: $grid-gutter;
  }

  &--border {
    &-primary {
      border: thin solid;
      border-color: $brand-primary;
    }
  }

  @media screen and (min-width: $screen-sm) {
    // margin-top: $grid-gutter * 2;
    // margin-bottom: $grid-gutter * 2;

    @include sectionvariation("--sm");
  }

  @media screen and (min-width: $screen-md) {
    @include sectionvariation("--md");
  }

  @media screen and (min-width: $screen-lg) {
    @include sectionvariation("--lg");
  }
}

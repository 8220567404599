@import 'variables';
@import '../assets/font-awesome/scss/font-awesome.scss';
@import '../../node_modules/normalize-scss/sass/normalize';
@include normalize();

// additional to normalize
* {
  box-sizing: border-box;
}

html {
  font-size: $font-size;
}

body {
  background: $body-bg;
  font-weight: $font-weight;
  line-height: $line-height; // overwriting normalize-sass
  color: $font-color;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

figure {
  margin: $grid-gutter * 2 0;
}

table {
  border-collapse: collapse;
}

main {
  display: block;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
    cursor: pointer;
  }
  // TODO: link variations to elements??
  &.is-secondary {
    color: $font-color;
  }

  &.is-underlined,
  &.is-secondary {
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

form {
  margin: $grid-gutter * 4 0;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0 0 $grid-gutter;
}

small {
  font-size: $font-size-smaller;
}

dl {
  margin: $grid-gutter 0;
}

.bold{
  font-weight: bold;
}
.primary{
  color: $brand-primary;
}

#skip-btn {
  display: block;
  position: absolute;
  left: -9999em;
  top: 0;
  background: #00bce2;
  padding: 0.5em;
  color: #fff;
  z-index: 99;

  &:focus {
    left: 0;
  }
}

.superscript {
  position: relative;
  bottom: 1ex;
  font-size: 60%;
}

// TODO: where to put this?!
.container {
  &--dark {
    background: #000;
    color: #fff;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

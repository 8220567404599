@import "src/styles/variables";
@import "src/styles/mixins";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  outline: none;

  &:before {
    @include fa-icon();
    content: prepend-slash($fa-var-info-circle);
    color: $brand-primary;
    font-size: $icon-size;
    padding: $grid-gutter 1rem;
    position: relative;
    z-index: 0;
    margin-top: $grid-gutter * -1;
    margin-bottom: $grid-gutter * -1;
  }

  // right arrow style
  &-content {
    opacity: 0;
    min-width: 200px; // for now
    background-color: $bg-info;
    color: $brand-normal;
    text-align: center;

    position: absolute;
    top: 50%;
    right: 100%;
    display: inline-block;
    border-radius: $border-radius;
    transform: translateY(-50%);
    height: 0;
    overflow: hidden;
    transition: opacity .3s ease .3s;
    z-index: 1;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: .5rem solid transparent;
      border-bottom: .5rem solid transparent;
      border-left: .5rem solid $bg-info;
      position: absolute;
      right: -.5rem;
      top: 50%;
      transform: translateY(-50%);
    }

    a {
      color: $brand-normal;
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &:hover,
  &:focus,
  &.is-focused {
    .tooltip-content {
      padding: $grid-gutter;
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }

  // default - right on mobile, bottom/top on desktop
  &--bottom,
  &--top {
    .tooltip-content {
      margin-top: $grid-gutter;
      margin-bottom: $grid-gutter;
      top: 100%;
      right: auto;
      left: 50%;
      transform: translateX(-50%);

      &:after {
        border-left: .5rem solid transparent;
        border-right: .5rem solid transparent;
        border-bottom: .5rem solid $bg-info;
        border-top: none;
        right: 50%;
        top: -.5rem;
        bottom: auto;
        transform: translateX(50%);
      }
    }
  }
  &--bottom {
    .tooltip-content {
      top: auto;
      bottom: 100%;

      &:after {
        border-top: .5rem solid $bg-info;
        border-bottom: none;
        top: auto;
        bottom: -.5rem;
      }
    }
  }

  &--left {
    .tooltip-content {
      right: auto;
      left: 100%;

      &:after {
        border-right: .5rem solid $bg-info;
        border-left: none;
        left: -.5rem;
        right: auto;
      }
    }
  }

  @media screen and (max-width: $screen-sm) {
    &--bottom {
      .tooltip-content {
        min-width: 160px;
      }
    }
  }
}

@import "src/styles/variables";

.forgot-password-email,
.forgot-password-done {
  padding-top: 10vh;

  & :after {
    bottom: 1rem;
  }

  & fieldset {
    margin: 0 0 1.625rem;
  }

  & form {
    margin: 0 auto;
    & fieldset {
      max-width: 350px;
      margin: 0 auto;
    }
  }

  & .text--error:before {
    display: none;
  }
  & .text--error {
    padding-left: 0;
  }

  & .h1,
  & .h3,
  & .h5 {
    text-transform: none !important;
  }
}

.forgot-password__title {
  text-align: center;
  margin-bottom: 0;
  height: 3.5rem;
  text-transform: initial;

  &:after {
    content: none !important;
  }

  &.blue {
    color: $brand-primary;
  }
}

.forgot-password__input {
  margin-top: 0 !important;
  margin-bottom: 2rem;
}

.forgot-password__button {
  display: block;
  margin: 0 auto;
}

.forgot-password__link {
  margin-top: 2.5rem;
  display: block;
}

.forgot-password-done {
  & svg {
    margin-bottom: 2rem;
  }

  & .forgot-password__link {
    margin-top: 0.5rem;
  }
}

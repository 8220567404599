@import "src/styles/variables";
@import "src/styles/mixins";

.datalist {
  dt {
    margin-bottom: .5em;
    font-size: $font-size-smaller;
  }
  dd {
    margin: 0;
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

@font-face {
  font-family: "peroregular";
  src: url("#{$fontPath}pero-regular-webfont.eot");
  src: url("#{$fontPath}pero-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}pero-regular-webfont.woff2") format("woff2"),
    url("#{$fontPath}pero-regular-webfont.woff") format("woff"),
    url("#{$fontPath}pero-regular-webfont.ttf") format("truetype"),
    url("#{$fontPath}pero-regular-webfont.svg#peroregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "perobold";
  src: url("#{$fontPath}pero-bold-webfont.eot");
  src: url("#{$fontPath}pero-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}pero-bold-webfont.woff2") format("woff2"),
    url("#{$fontPath}pero-bold-webfont.woff") format("woff"),
    url("#{$fontPath}pero-bold-webfont.ttf") format("truetype"),
    url("#{$fontPath}pero-bold-webfont.svg#perobold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "perosemibold";
  src: url("#{$fontPath}pero-semibold-webfont.eot");
  src: url("#{$fontPath}pero-semibold-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}pero-semibold-webfont.woff2") format("woff2"),
    url("#{$fontPath}pero-semibold-webfont.woff") format("woff"),
    url("#{$fontPath}pero-semibold-webfont.ttf") format("truetype"),
    url("#{$fontPath}pero-semibold-webfont.svg#perosemibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "perolight";
  src: url("#{$fontPath}pero-light-webfont.eot");
  src: url("#{$fontPath}pero-light-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}pero-light-webfont.woff2") format("woff2"),
    url("#{$fontPath}pero-light-webfont.woff") format("woff"),
    url("#{$fontPath}pero-light-webfont.ttf") format("truetype"),
    url("#{$fontPath}pero-light-webfont.svg#perolight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "perosemilight";
  src: url("#{$fontPath}pero-semilight-webfont.eot");
  src: url("#{$fontPath}pero-semilight-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}pero-semilight-webfont.woff2") format("woff2"),
    url("#{$fontPath}pero-semilight-webfont.woff") format("woff"),
    url("#{$fontPath}pero-semilight-webfont.ttf") format("truetype"),
    url("#{$fontPath}pero-semilight-webfont.svg#perosemilight") format("svg");
  font-weight: normal;
  font-style: normal;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 1rem;
  &.title {
    line-height: $line-height-header; // mockup number is 1
    font-weight: $font-weight-bolder;
    font-family: $font-family-header;
    letter-spacing: 0.05em;

    .container--dark & {
      font-family: $font-family-header-regular;
    }

    &.title--strong {
      text-transform: capitalize;
    }

    &.title--strong-no-cap,
    &.title--strong {
      &:after {
        content: "";
        display: block;
        height: 3px;
        width: 3.75rem;
        background: $brand-primary;
        margin: 1rem 0 $grid-gutter * 2;
      }
    }

    &.title--primary {
      color: $brand-primary;
    }
    &.title--error {
      color: $brand-danger;
    }
    &.title--success {
      color: $brand-success;
    }

    &.title--bottom-border {
      border-bottom: thin black solid;
      padding-bottom: $grid-gutter;
      margin-bottom: 0;
    }
  }
}

h1,
.h1 {
  &.title {
    font-size: ($h1-font-size / $font-size) + $font-unit;
    font-family: $font-family-header-light;
    letter-spacing: 0;

    .container--dark & {
      font-size: ($h2-font-size / $font-size) + $font-unit;

      @media screen and (min-width: $screen-sm) {
        font-size: ($h1-font-size / $font-size) + $font-unit;
      }
    }
  }
}
.title {
  &.h2 {
    font-size: ($h2-font-size / $font-size) + $font-unit;
  }
  &.h3 {
    font-size: ($h3-font-size / $font-size) + $font-unit;
  }
  &.h4 {
    font-size: ($h4-font-size / $font-size) + $font-unit;
  }
}

@media screen and (min-width: $screen-sm) {
  h1,
  .h1 {
    &.title {
      font-size: ($h1-font-size-desktop / $font-size) + $font-unit;
    }
  }
  h2,
  .h2 {
    &.title {
      font-size: ($h2-font-size-desktop / $font-size) + $font-unit;
    }
  }
  h3,
  .h3 {
    &.title {
      font-size: ($h3-font-size-desktop / $font-size) + $font-unit;
    }
  }
  h4,
  .h4 {
    &.title {
      font-size: ($h4-font-size-desktop / $font-size) + $font-unit;
    }
  }
}

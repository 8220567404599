@import "src/styles/variables";
@import "src/styles/mixins";

.progressbar {
  font-family: $font-family-header-regular;
  font-size: $font-size-small;

  @media screen and (min-width: $screen-sm) {
    font-size: $font-size;
  }

  @media screen and (min-width: $screen-lg) {
    &:before,
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  &__items {
    margin: 0;
    padding: 0;
    list-style: none;
    background: $bg-secondary;
    border-radius: $grid-gutter * 2;
    counter-reset: progress-counter;
    overflow: hidden;
    white-space: nowrap;

    @media screen and (min-width: $screen-md) {
      &--col3 {
        .progressbar__item {
          width: percentage(1/3);
        }
      }

      &--col4 {
        .progressbar__item {
          width: percentage(1/4);
        }
      }

      &--col5 {
        .progressbar__item {
          width: percentage(1/5);
        }
      }
    }
  }

  &__item {
    position: relative;
    display: inline-block;

    &:last-child {
      .progressbar__link:after {
        border: none;
      }
    }

    &--current {
      min-width: 65%; // for now
      &:last-child {
        min-width: 100%;
      }

      @media screen and (min-width: $screen-md) {
        min-width: 0 !important;
      }

      .progressbar__link {
        span {
          position: relative;
          left: 0;
          opacity: 1;
        }
      }

      ~ .progressbar__item {
        .progressbar__link {
          // width: auto;
          background: transparent;
          color: $gray-light;

          &:before {
            display: none;

            @media screen and (min-width: $screen-md) {
              display: inherit;
            }
          }

          &:after {
            background: $bg-secondary;
            border-color: $gray-lighter;
          }

          &:not(.is-disabled):hover {
            &, &:after {
              background: darken($bg-secondary, 5%);
            }
          }
        }
      }
    }
  }

  &__link {
    display: inline-block;
    vertical-align: middle;
    padding: $grid-gutter $grid-gutter * 2;
    padding-right: 0;
    width: 100%;
    background: $brand-primary;
    color: #fff;
    white-space: nowrap;
    text-decoration: none;
    min-width: 2rem;

    &.is-disabled {
      cursor: default;
    }

    @media screen and (min-width: $screen-sm) {
      padding: $grid-gutter $grid-gutter * 3;
      padding-right: $grid-gutter;
    }

    &:before {
      position: relative;
      content: counter(progress-counter);
      counter-increment: progress-counter;
      padding-right: .5rem;
      z-index: 1;
      display: inline-block; // to avoid text-decoration
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: $grid-gutter * -1;
      width: 3rem;
      height: 3rem;
      cursor: pointer;

      background: $brand-primary;
      border-top: 1px solid rgba(255,255,255,.5);
      border-right: 1px solid rgba(255,255,255,.5);
      transform: translateY(-50%) rotate(45deg) skew(10deg,10deg);
    }

    &:hover {
      &, &:after {
        background: darken($brand-primary, 5%);
        transition: background .3s ease;
      }
    }

    span {
      position: absolute;
      left: -300vw;
      z-index: 1;
      display: inline-block; // to avoid text-decoration
      opacity: 0;
      transition: opacity .3s ease .1s;

      @media screen and (min-width: $screen-md) {
        position: relative;
        left: 0;
        opacity: 1;
      }
    }
  }
}

@import "src/styles/variables";

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  & img {
    animation: logo-spin infinite 0.8s linear;
    user-select: none;
  }

  &--center {
    & img {
      margin: auto;
    }
  }

  &--fullpage {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    opacity: 0.8;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: $bg-primary !important;

    & > img {
      width: 50px;
      height: 50px;
    }
  }
  &--white {
    background-color: transparent;
  }
  &--dark {
    background-color: transparent;
  }
}

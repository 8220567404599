.customOverlay {
  background: rgba(60, 60, 60, 0.9);
}

.customModal {
  background: black;
  max-width: 100%;
  width: 60%;
  height: 90%;
}

@media screen and (max-width: 1000px) {
  .customOverlay {
    background: rgba(60, 60, 60, 0.9);
  }
  .customModal {
    background: black;
    max-width: 100%;
    width: 100%;
    height: 100%;
    top: -2%;
    left: -15px;
  }
}

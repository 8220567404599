@import "src/styles/variables";
@import "src/styles/mixins";

.transaction-receipt {
  > div > ul {
    max-height: 6.5rem;
    overflow: auto;
  }

  > .total-text {
    background: $bg-info-darker;
    padding: $grid-gutter;
    text-align: right;
  }
}

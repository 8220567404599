@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";
@import "../../assets/font-awesome/scss/mixins";
@import "../../assets/font-awesome/scss/variables";

.expandable-panel {
  &--box {
    &.expanded {
      border-radius: 1rem;
      border: $brand-primary thin solid;
      overflow: hidden;
    }
  }

  &__header {
    &--box {
      &.expanded {
        button {
          color: $brand-primary;
          background: white;
          border-bottom: $brand-primary thin solid;
          border-radius: 0;
          margin: 0;
        }
      }
    }

    &--card-action {
      color: $brand-primary;
      border: $brand-primary thin solid;
      border-radius: 0 0 4px 4px;
      margin: -1px -1px 0 -1px;

      button {
        width: 100%;
        padding: $grid-gutter 0 !important;
      }

      &.expanded {
        border-radius: 0;
      }
    }
  }

  &__body {
    padding: 1rem;
    &--info-bg {
      background: $bg-info;
    }
    &--modal {
      &.expanded {
        position: absolute;
        transform: translateX(-80%);
        width: $screen-xxs; // mobile
        z-index: 9;
        box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
      }
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

.logo-caa {
  display: inline-block;
  vertical-align: middle;
  margin: 0 $grid-gutter;
  width: 100px;

  svg {
    width: 100%;
    height: auto;
    display: block;
    path {
      fill: #000;
    }
  }

  &--inverse {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  &--small {
    width: 3rem;
  }
  &--smaller {
    width: 2rem;
  }

  &--large {
    width: 10rem;
  }

  .container--dark & {
    svg {
      path {
        fill: #fff;
      }
    }

    &--inverse {
      svg {
        path {
          fill: #000;
        }
      }
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";

.footer {
  background: #000;
  padding: $grid-gutter/2 $grid-gutter;

  &__inner {
    position: relative;
    @include container();
  }

  @media screen and (min-width: $screen-sm) {
    padding: $grid-gutter $grid-gutter * 2;
  }
}

@import "src/styles/variables";

.car-marker {
  &__marker {
    transform: translate(-50%, -100%);
    background-position: 0px 0px;
    height: 42px;
    line-height: 42px;
    width: 28px;
    text-align: center;
    cursor: pointer;
    color: white;
    position: absolute;
    font-size: 11px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    z-index: 10;

    &_on-top {
      z-index: 1000001;
    }

    &__badge {
      background-color: #fff;
      position: absolute;
      top: -12px;
      width: 24px;
      height: 24px;
      border: thin solid #000;
      border-radius: 12px;
      color: #000;
      line-height: 22px;
      text-align: center;
    }
  }
}

.car-details {
  &_container {
    z-index: 10;
    box-sizing: content-box;
    padding: 22px;
    width: 280px;
    background: white;
    position: relative;
    transform: translate(-50%, -110%);
    border-radius: 0.5rem;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);

    &_on-top {
      z-index: 1000000;
    }
  }

  &_info-bottom {

    text-align: center;
    background: #000000;
    color: #ffffff;
    width: 325px;
    margin: 20px 0 -22px -23px;
    padding: 20px 5px;
    border-radius: 0 0 8px 8px;
  }

  &_top-car-icon {
    top: -25px;
    position: absolute;
    left: 120px;
    & > img {
      width: 80px;
    }
  }

  &_brief {
    text-align: center;
    border-bottom: thin solid lightgrey;
    padding: 10px 0 20px 0;
    font-weight: $font-weight-bold;
    flex: 1;
    & > div {
      margin: 5px 0;
    }
    & .car-details_plate {
      font-weight: $font-weight-bold;
      font-size: $font-size-large;
    }
    & .car-details_title {
      font-size: $font-size-base;
      font-weight: $font-weight-regular;
    }
  }
  &_metric {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    & > div {
      color: $brand-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        margin-right: 5px;
      }
    }
  }

  &_directions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    margin: 10px 0;
    font-size: $font-size-large;
    color: $brand-primary;
  }

  &_message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    margin: 10px 0;
    font-size: $font-size-large;
  }


  &_button-bookcar {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    color: $brand-info-light;
    background-color: $brand-primary;
    padding: 15px 20px;
    border-radius: 40px;

    &:hover {
      cursor: pointer;
    }

    &-inverse {
      color: $brand-primary;
      border: 1px solid $brand-primary;
      background-color: transparent;
    }


  }
}

.reservation-float-info-window {
  position: absolute;
  top: 8em;
  right: 0.7em;
  width: calc(384px + 2em);
  padding: 1em;
  border-radius: 0.5em;
  background: $brandWhite;
  box-shadow: 0 0 3px $brandGray01;
  .car-details_info-bottom {
    width: 416px;
    margin: 20px 0 -50px -15px;
  }
  .car-details_top-car-icon {
    left: 170px;
  }
}

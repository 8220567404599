@import "src/styles/variables";
@import "src/styles/mixins";

.table {
  font-size: $font-size-small;
  font-weight: $font-weight-bold;

  &,
  &__wrapper {
    width: 100%;
  }

  &__wrapper {
    margin: $grid-gutter * 2 auto;
  }

  // hold on for now
  // &__wrapper {
  //   overflow-x: auto;
  // }

  &--stripe {
    tbody > tr:nth-child(even) {
      background: $bg-primary;
    }
  }

  .td,
  .tr {
    &--primary {
      color: $brand-primary;
    }

    &--currency {
      text-align: right;
    }

    &--large {
      font-size: $font-size-large;
    }
  }

  td,
  th {
    padding: 1rem;
  }

  thead {
    font-size: $font-size-base;
  }

  tbody {
    td {
      border-bottom: 1px dashed $gray-light;
    }

    tr:first-child td {
      border-top: 2px solid $gray-light;
    }
  }
}

@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.memberCard {
  width: 85%;
  margin-right: 5px;
  align: right;
  display: inline-block;


@media screen and (min-width: $screen-xxs) {
  min-width: 75px;
  padding-right: 5px;
  padding-top: 5px;
}
@media screen and (min-width: $screen-xs) {
  min-width: 60px;
  padding-right: 5px;
  padding-top: 5px;
}


}

.marginTop{
  margin-top: 45px;
}


@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/elements/icons/icons";

.navbar {
  flex: 1;
  display: block;
  @include container();
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  max-width: $screen-lg;
  &:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  &__container {
    background-color: $body-bg;
    flex: 1;
    box-shadow: 1px 1px 10px $gray-lighter;
    overflow-x: auto;
    white-space: nowrap;
  }

  &__item {
    display: inline-block;
    margin-right: $grid-gutter*2;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    padding: $grid-gutter 0;

    @media screen and (min-width: $screen-xs) {
      padding: $grid-gutter*2 0;
    }

    &--active {
      border-bottom: 3px solid $brand-primary;
      color: $brand-primary !important;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &--normal {
      color: $gray-lighter;
    }
  }
}

@import "src/styles/variables";
@import "src/styles/mixins";

.main {
  @include container();
  padding-top: $grid-gutter * 3;
  padding-bottom: $grid-gutter * 3;
  min-height: 100vh; // for test

  > [for="nav-checkbox"] {
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 9;
  }

  &--invert {
    background: black;
    color: white;
  }

  &--center {
    text-align: center;
  }
}

#nav-checkbox:checked ~ #main > [for="nav-checkbox"] {
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
}
